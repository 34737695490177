import React, { useState } from 'react';
import { findDeadPlayers } from './findDeadPlayers'; // Import your function

function App() {
  const [logFileContents, setLogFileContents] = useState('');
  const [analyzedData, setAnalyzedData] = useState(null); // State to store analyzed data

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        setLogFileContents(contents);
      };
      reader.readAsText(file);
    }
  };

  const handleAnalyzeClick = () => {
    if (logFileContents) {
      const filteredCaptureData = findDeadPlayers(logFileContents);
      setAnalyzedData(filteredCaptureData); // Store analyzed data in state
    }
  };

  return (
    <div style={{backgroundColor: "PowderBlue"}}>
      <input type="file" onChange={handleFileInputChange} />
      <button onClick={handleAnalyzeClick}>Analyze</button>
      {/* Render the rest of your UI */}

      {/* Display the analyzed data */}
      {analyzedData && (
        <div>
          {/* Display the playersDeadOnCapture information */}
          {Object.keys(analyzedData).map((key) => (
            <div style={{padding: "2px", marginLeft: "2px"}} key={key}>
<h3>{key + (analyzedData[key][0] ? " - " + JSON.stringify(analyzedData[key][0].initialTimestamp) : "")}</h3>

              <div style={{backgroundColor: "goldenrod", borderRadius: "50px", padding: "2px"}}>
<h3>
              Players Dead on Capture: {analyzedData[key].playersDeadOnCapture.join(', ')}
</h3>
              </div>
              <details>
              <ul>
                {analyzedData[key].map((entry, index) => (
                  <ul key={index} style={{marginTop: "4px", marginBottom: "4px"}}>
                    {entry.type === 'killed' && (
                      <div style={{backgroundColor: `${entry.player.team === "Blue" ? "skyblue" : "crimson"}`, padding: "2px", borderRadius: "20px"}}>
                        <h3>{entry.player.name} killed {entry.opponent.name}</h3>
                          <h4>{entry.timestamp}</h4>
                      </div>
                    )}
                    {entry.type === 'spawned' && (
                      <>
                        <div style={{backgroundColor: `${entry.player.team === "Blue" ? "skyblue" : "crimson"}`, padding: "2px", borderRadius: "20px"}}>
                          <h3>{entry.player.name} spawned as {entry.player.class}</h3>
                          <h4>{entry.timestamp}</h4>
                        </div>
                      </>
                    )}
                  </ul>
                ))}
              </ul>
              </details>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default App;
